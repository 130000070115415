import * as React from 'react';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface Props {
    images: Array<{ src: string; description: string }>;
    title: string;
    subtitles: string[];
    cols?: number;
}

import leftArrow from './images/leftArrow.svg';
import rightArrow from './images/rightArrow.svg';

export default ({ images, title, subtitles, cols = 2 }: Props) => {
    const [lightBox, setLightBox] = useState(-1);

    const nextLightBox = (event: any) => {
        event.stopPropagation();

        if (lightBox === images.length - 1) {
            setLightBox(0);
        } else {
            setLightBox(lightBox + 1);
        }
    };

    const prevLightBox = (event: any) => {
        event.stopPropagation();

        if (lightBox <= 0) {
            setLightBox(images.length - 1);
        } else {
            setLightBox(lightBox - 1);
        }
    };

    const closeLightBox = (event: any) => {
        event.stopPropagation();

        setLightBox(-1);
    };

    useHotkeys('esc', () => setLightBox(-1));
    useHotkeys(
        'left, w',
        (e) => {
            if (lightBox !== -1) {
                prevLightBox(e);
            }
        },
        [prevLightBox],
    );
    useHotkeys(
        'right, d',
        (e) => {
            if (lightBox !== -1) {
                nextLightBox(e);
            }
        },
        [nextLightBox],
    );

    const colsMap = [];
    for (let i = 0; i < cols; i++) {
        colsMap.push(i);
    }

    return (
        <div className="exhibition-page">
            <div className="overview">
                <div>
                    <h1>{title}</h1>
                    {subtitles.map((subtitle, index) => (
                        <p className="subtitle" key={index}>
                            {subtitle}
                        </p>
                    ))}
                    <div className="commission">REQUEST COMMISSION</div>
                </div>
            </div>
            <div className="wall">
                {colsMap.map((column) => (
                    <React.Fragment key={column}>
                        <div>
                            {images
                                .filter(
                                    (image, index) => index % cols === column,
                                )
                                .map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.src}
                                        onClick={() =>
                                            setLightBox(index * cols + column)
                                        }
                                    />
                                ))}
                        </div>
                        {column !== cols - 1 && <div className="split" />}
                    </React.Fragment>
                ))}
            </div>

            {lightBox !== -1 && (
                <div className="lightbox" onClick={closeLightBox}>
                    <div className="arrow" onClick={prevLightBox}>
                        <img src={leftArrow} />
                    </div>
                    <div className="lightbox-middle">
                        <img
                            src={images[lightBox].src}
                            onClick={closeLightBox}
                        />
                        <div className="description">
                            <div>{images[lightBox].description}</div>
                        </div>
                    </div>
                    <div className="arrow" onClick={nextLightBox}>
                        <img src={rightArrow} />
                    </div>
                </div>
            )}
        </div>
    );
};
