import * as React from 'react';

import Exhibit from '../../components/Exhibit';
import Shell from '../../components/Shell';

import science1 from '../../components/images/science1.jpg';
import science2 from '../../components/images/science2.jpg';
import science3 from '../../components/images/science3.jpg';
import science4 from '../../components/images/science4.jpg';
import science5 from '../../components/images/science5.jpg';
import science6 from '../../components/images/science6.jpg';
import science7 from '../../components/images/science7.jpg';
import science8 from '../../components/images/science8.jpg';
import science9 from '../../components/images/science9.jpg';

export default () => {
    return (
        <Shell>
            <div className="exhibition-page">
                <Exhibit
                    images={[
                        { src: science1, description: '' },
                        { src: science2, description: '' },
                        { src: science3, description: '' },
                        { src: science4, description: '' },
                        { src: science5, description: '' },
                        { src: science6, description: '' },
                        { src: science7, description: '' },
                        { src: science8, description: '' },
                        { src: science9, description: '' },
                    ]}
                    title="Science"
                    cols={3}
                    subtitles={['Science is cool']}
                />
            </div>
        </Shell>
    );
};
